import React, { useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Card from "./card.js"
import { LabeledToggle } from "./toggle"
import { FlexGrid, FlexGridCell } from "../components/flex-grid"

const ToyList = ({ data }) => {
  const [doScale, setDoScale] = useState(true)
  const [showDebugInfo, setShowDebugInfo] = useState(false)
  const [onlyScalable, setOnlyScalable] = useState(false)
  const [displayUnit, setDisplayUnit] = useState("mm")

  const mmPerInch = 25.4

  const toys = data.toys.nodes

  function getImage(item) {
    if (item.data.Image.localFiles && item.data.Image.localFiles.length === 1) {
      return item.data.Image.localFiles[0].childImageSharp
    } else {
      console.log("item needs exactly one image", item.data.Name)
      return null
    }
  }

  toys.forEach(item => {
    const image = getImage(item)
    if (image) {
      item.longerDimensionPx = Math.max(
        image.original.width,
        image.original.height
      )
    }
  })

  // choose ref item with min "ratio"
  //   refPx         1
  //   ----- * --------------
  //   refMM   refLongerDimPx
  let refToy = undefined
  let refToyRatio = false
  toys.forEach(item => {
    if (item.fields.diameterInPixels > 0.0) {
      const ratio =
        (item.fields.diameterInPixels / item.data.Diameter_mm_calc) *
        (1 / item.longerDimensionPx)
      if (!refToyRatio || ratio < refToyRatio) {
        refToy = item
        refToyRatio = ratio
      }
    }
  })

  // set scale for all items
  toys.forEach(item => {
    if (item.fields.diameterInPixels > 0.0) {
      item.scale = getScale(item)
      item.isScalable = true
    } else {
      item.isScalable = false
    }
  })

  function getScale(item) {
    /*
    Gets the scale required to display all images in correct relative scale to
    each other.
    Given one "reference" image. It's best to choose as the reference image the
    largest item (or with highest mm/px ?) so that the largest image gets a
    scale of 1 and the rest get a scale less than 1. Otherwise, we'll get
    scale factors greater than 1, and that makes page layout hard.

    This formula is:
     iMM   refPx    iLongerDimPx
     --- * ----- * --------------
     iPx   refMM   refLongerDimPx

    |-----------|
      This is a pretty simple
      proportion to scale each
      image relative to a chosen
      scale.
      Dimensional analysis:
      mm cancels mm and px cancels px

                   |---------------|
                    This term accounts for each image having a different
                    starting size (in pixels).
                    Each picture is a different size to start,
                    but we force them all into squares of the same size.
                    *Assuming* that all images start larger than the
                    display size, it doesn't matter what the actual display
                    size is, only that it's the same for all images.
                    
    */

    return (
      (item.data.Diameter_mm_calc / item.fields.diameterInPixels) *
      (refToy.fields.diameterInPixels / refToy.data.Diameter_mm_calc) *
      (item.longerDimensionPx / refToy.longerDimensionPx)
    )
  }

  return (
    <div>
      {/* Control Panel */}
      <p className="text-sm pt-1">
        All items are scaled relative to one another. Just like real life!
        <br />
        Ordered by diameter: smallest to largest.
      </p>

      <FlexGrid>
        {toys
          .filter(toy => (onlyScalable ? toy.isScalable : true))
          .map((item, itemIdx) => (
            <FlexGridCell key={item.recordId}>
              <Card
                key={item.recordId}
                item={item}
                itemIdx={itemIdx}
                doScale={doScale}
                showDebugInfo={showDebugInfo}
                displayUnit={displayUnit}
              />
            </FlexGridCell>
          ))}
      </FlexGrid>

      <ListDebugInfo
        refToy={refToy}
        refToyRatio={refToyRatio}
        showDebugInfo={showDebugInfo}
      />

      <LabeledToggle
        label={"Relative scaling:"}
        value={doScale}
        onClick={() => setDoScale(!doScale)}
      />
      <LabeledToggle
        label={"Debug info:"}
        value={showDebugInfo}
        onClick={() => setShowDebugInfo(!showDebugInfo)}
      />
      <LabeledToggle
        label={"Show only scalable:"}
        value={onlyScalable}
        onClick={() => setOnlyScalable(!onlyScalable)}
      />
      <LabeledToggle
        label={"Inches"}
        value={displayUnit === "in"}
        onClick={() =>
          displayUnit === "in" ? setDisplayUnit("mm") : setDisplayUnit("in")
        }
      />
    </div>
  )
}

const ListDebugInfo = props => {
  if (!props.showDebugInfo) {
    return null
  }
  return (
    <div className="text-xs text-gray-500">
      refToy: {props.refToy.data.Name}
      <br />
      refToyRatio: {props.refToyRatio}
    </div>
  )
}

export default ToyList
