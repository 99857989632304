// A FlexGrid containing a list of FlexGridCells forms a responsive grid with
// equal height cells in each row (like a table).
// There is a gutter between cells (thanks to `p-2`).
// There is no whitespace around the FlexGrid (so you can use or not use padding
// on the containing element (like your Layout) (thanks to `-m-2`).
// You probably want to set `w-full h-full` on the child element within each
// FlexGridCell so that it expands to fill the cell.
// https://tailwindcss.com/components/grids/#column-spacing
// > To prevent horizontal scrolling in full width layouts, add overflow-hidden to another parent container, or
// > compensate for the negative margin with matching horizontal padding.

import React from "react"

export const FlexGrid = props => {
  return (
    <div className="overflow-hidden">
      <div className="min-h-screen flex flex-wrap -m-2">{props.children}</div>
    </div>
  )
}
export const FlexGridCell = props => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-2">
      {props.children}
    </div>
  )
}
