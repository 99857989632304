import React from "react"

export const Toggle = props => {
  return (
    <div
      onClick={props.onClick}
      // className={props.value ? "bg-green" : "bg-grey"}
      className={`w-12 h-6 rounded-full shadow inline-block cursor-pointer ${
        props.value ? "bg-green-400" : "bg-gray-400"
      } ${props.className}`}
    >
      <div
        style={{
          transform: `translateX(${props.value ? 100 : 0}%)`,
          transition: "transform 0.15s ease",
        }}
        className="w-1/2 h-full bg-white rounded-full shadow"
      />
    </div>
  )
}

export const LabeledToggle = props => {
  return (
    <div className="flex">
      <p>{props.label}</p>
      <Toggle className="ml-2" value={props.value} onClick={props.onClick} />
    </div>
  )
}
