import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const LabeledDimension = props => {
  const mmPerInch = 25.4
  if (props.value == null) {
    return <div>&emdash;</div>
  }
  let displayValue = props.value
  if (props.displayUnit === "in") {
    displayValue = props.value / mmPerInch
  }

  return (
    <div className={props.className}>
      <span className="text-indigo-600 font-semibold">
        {displayValue.toFixed(1)} {props.displayUnit}
      </span>{" "}
      <span className="text-gray-600 text-sm">{props.label}</span>
    </div>
  )
}

LabeledDimension.defaultProps = {
  label: "",
  value: null,
  displayUnit: "mm",
}

const Card = props => {
  return (
    <div className="bg-white mt-5 rounded-lg overflow-hidden shadow-lg w-full h-full bg-gray-100">
      <div
        className={`relative ${
          props.showDebugInfo ? "bg-gray-200" : "bg-white"
        }`}
        // when using a plain img tag, this is needed to maintain a square aspect ratio as the columns adjust in size,
        // (matches up with w-full)
        // but gatsby's Img component includes it already, so it's not needed
        // style={{ paddingBottom: "100%" }}
      >
        {props.item.data.Image.localFiles &&
        props.item.data.Image.localFiles.length > 0 &&
        props.item.data.Image.localFiles[0].childImageSharp ? (
          <Img
            style={{
              transform: props.doScale ? `scale(${props.item.scale})` : "",
            }}
            className="absolute top-0 h-full w-full object-contain"
            fluid={props.item.data.Image.localFiles[0].childImageSharp.fluid}
          />
        ) : (
          <div>no image</div>
        )}
        {!props.item.isScalable && props.doScale && (
          <div className="absolute bottom-0 right-0 p-4">
            <div className="bg-orange-200 rounded-full px-2 py-1 text-xs uppercase font-semibold tracking-wide text-orange-900">
              Not To Scale
            </div>
          </div>
        )}
        <div className="absolute top-0">
          <CardDebugInfo {...props} />
        </div>
      </div>
      <div className="p-6">
        <Link to={props.item.fields.slug}>
          <div className="text-gray-600 text-xs uppercase font-semibold tracking-wide">
            {props.item.data.Brand[0].data.Name}
          </div>
          <h4 className="font-semibold text-lg leading-tight truncate">
            {props.item.data.Name}
          </h4>
          <div>
            {props.item.fields.formattedPrice
              ? props.item.fields.formattedPrice
              : "-"}
          </div>
          <LabeledDimension
            className="mt-2"
            value={props.item.data.Diameter_mm_calc}
            displayUnit={props.displayUnit}
            label={"diameter"}
          />
          <LabeledDimension
            value={props.item.data.Length_mm_calc}
            displayUnit={props.displayUnit}
            label={"length"}
          />
        </Link>
      </div>
    </div>
  )
}

const CardDebugInfo = props => {
  if (!props.showDebugInfo) {
    return null
  }
  return (
    <div className="text-xs text-gray-500 p-2">
      <div className="text-xs font-bold">{props.itemIdx}</div>
      {props.item.isScalable ? "to scale" : "not to scale"} <br />
      scale: {props.item.scale ? props.item.scale.toFixed(2) : "-"} <br />
      dia px:{" "}
      {props.item.fields.diameterInPixels
        ? props.item.fields.diameterInPixels.toFixed(2)
        : "-"}
    </div>
  )
}
export default Card
