import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import ToyList from "../components/ToyList"

export default ({ data, pageContext }) => {
  return (
    <Layout>
      <h2>{pageContext.category}</h2>
      <ToyList data={data} />
    </Layout>
  )
}

// This is identical to the query in index.js except for the category filter and the Show_on_homepage filter
// TODO: some abstraction (fragment?)
export const pageQuery = graphql`
  query($category: String) {
    toys: allAirtable(
      filter: {
        table: { eq: "Products" }
        data: {
          Category: { in: [$category] }
          Diameter_mm_calc: { gt: 0 }
          Published: { eq: true }
        }
      }
      sort: { order: ASC, fields: data___Diameter_mm_calc }
    ) {
      nodes {
        recordId
        fields {
          slug
          formattedPrice
          diameterInPixels
        }
        data {
          Brand {
            data {
              Name
            }
          }
          Image {
            localFiles {
              childImageSharp {
                original {
                  height
                  width
                }
                fixed(
                  height: 280
                  width: 280
                  fit: CONTAIN
                  background: "#FFFFFF"
                ) {
                  ...GatsbyImageSharpFixed
                }
                fluid(
                  maxHeight: 560
                  maxWidth: 560
                  fit: CONTAIN
                  background: "#FFFFFF"
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          Diameter_mm_calc
          Length_mm_calc
          Name
          Price
          Diameter_in_Pixels
          # Materials
          Category
          # Features
        }
      }
    }
  }
`
